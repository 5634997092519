<!--
 * @Author: Link
 * @Date: 2021-08-25 14:36:36
 * @LastEditTime: 2021-08-25 14:46:40
-->
<template>
  <div class="loading" v-if="loading" :style="`border-color:${color};box-shadow: 0px 0px 6px ${color}`"></div>
</template>

<script>
export default {
  props: ['loading', 'color']
}
</script>

<style lang='less' scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid rgb(112, 101, 241);
  box-shadow: 0px 0px 6px rgba(113, 101, 241, 0.39);
  animation: loop 1s infinite linear;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    height: 15px;
    // border-radius: 50%;
    background: #fff;
  }
}
@keyframes loop {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>