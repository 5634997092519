<!--
 * @Author: Link
 * @Date: 2021-08-20 10:52:47
 * @LastEditTime: 2021-09-02 11:45:07
-->
<template>
  <div>
    <div :class="`sidePanel ${isAnimation && 'animation'}`" @animationend="isAnimation = false">
      <div class="header-container">
        <img
          src="@/asset/icon/overview/shape2.png"
          class="shape"
          :style="`${activeTab == 0 && 'left:0'};${activeTab == 1 && 'right:0;transform:rotateY(180deg)'};`"
        />
        <div class="header">
          <div
            :class="`item ${activeTab == 0 && 'active'}`"
            @click="
              activeTab = 0
              isAnimation = true
            "
          >
            <span>支付金额</span>
          </div>
          <div
            :class="`item ${activeTab == 1 && 'active'}`"
            @click="
              activeTab = 1
              isAnimation = true
            "
          >
            <span>支付笔数</span>
          </div>
        </div>
      </div>
      <div
        class="content"
        :style="`border-top-left-radius:${activeTab == 0 ? '0' : '15px'};border-top-right-radius:${
          activeTab == 1 ? '0' : '15px'
        }`"
      >
        <div class="filter">
          <div class="switch">
            <div class="switch--active" :style="`left:${filter.switch == 'today' ? '0' : '50%'}`"></div>
            <div :class="`switch-item ${filter.switch == 'today' && 'active'}`" @click="filter.switch = 'today'">
              <span>今日</span>
            </div>
            <div
              :class="`switch-item ${filter.switch == 'yesterday' && 'active'}`"
              @click="filter.switch = 'yesterday'"
            >
              <span>昨日</span>
            </div>
          </div>
          <div class="filter-item filter-date">
            <i class="el-icon-date"></i> {{ filterDate[0] }} <span v-if="filterDate[1]">至 {{ filterDate[1] }}</span>
            <div class="date-picker">
              <el-date-picker
                v-model="filter.date"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              />
            </div>
          </div>
        </div>
        <div class="list">
          <loading :loading="loading" color="rgb(231, 20, 26)" />
          <div
            class="item"
            v-for="(item, index) in list"
            :key="item.businessId"
            :style="`animation-delay:${index * 50}ms`"
          >
            <div class="rank">
              <img v-if="index < 3" :src="require(`@/asset/icon/overview/rank${index + 1}.png`)" alt="" srcset="" />
              <img v-else :src="require('@/asset/icon/overview/rank_other.png')" alt="" srcset="" />
              <span>{{ index + 1 }}</span>
            </div>
            <div class="name">{{ item.businessName }}</div>
            <div
              class="value"
              :style="`color:${
                index == 0
                  ? 'rgb(224, 71, 67)'
                  : index == 1
                  ? 'rgb(239, 122, 74)'
                  : index == 2
                  ? 'rgb(145, 155, 249)'
                  : 'rgb(27, 29, 33)'
              }`"
            >
              {{ activeTab == 0 ? item.money : item.orderCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '../_com/loading.vue'
export default {
  components: { loading },
  data() {
    return {
      isAnimation: false,
      activeTab: 0,
      today: '',
      yesterday: '',
      filter: {
        switch: 'today', //yesterday
        date: ''
      },
      loading: false,
      list: []
    }
  },
  computed: {
    filterDate() {
      if (!this.filter.date) {
        return ['开始日期', '结束日期']
      }
      let _start = this.filter.date[0]
      let _end = this.filter.date[1]
      let start = {
        year: _start.getFullYear(),
        month: _start.getMonth() + 1,
        day: _start.getDate()
      }
      let end = {
        year: _end.getFullYear(),
        month: _end.getMonth() + 1,
        day: _end.getDate()
      }
      let start_str =
        (start.month < 10 ? '0' + start.month : start.month) + '-' + (start.day < 10 ? '0' + start.day : start.day)
      let end_str = (end.month < 10 ? '0' + end.month : end.month) + '-' + (end.day < 10 ? '0' + end.day : end.day)
      let nowYear = new Date().getFullYear()
      if (nowYear !== start.year) start_str = start.year + '-' + start_str
      if (nowYear !== end.year) end_str = end.year + '-' + end_str
      if (start_str == end_str) {
        return [start_str]
      }
      return [start_str, end_str]
    }
  },
  watch: {
    'filter.switch'(v) {
      if (v === 'today') {
        this.filter.date = this.today
      } else {
        this.filter.date = this.yesterday
      }
    },
    'filter.date'() {
      this.getRank()
    },
    activeTab() {
      setTimeout(() => {
        this.getRank()
      }, 500)
    }
  },
  created() {
    this.today = [new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(23, 59, 59, 0))]
    let temp = new Date()
    this.yesterday = [
      new Date(new Date(new Date().setDate(temp.getDate() - 1)).setHours(0, 0, 0, 0)),
      new Date(new Date(new Date().setDate(temp.getDate() - 1)).setHours(23, 59, 59, 0))
    ]
    this.filter.date = this.today
  },
  methods: {
    getRank() {
      this.list = []
      this.loading = true
      let params = {}
      if (this.filter.date) {
        params = {
          endTime: this.filter.date[1].getTime(),
          startTime: this.filter.date[0].getTime(),
          orderBy: this.activeTab
        }
      }
      this.$api.mall
        .mall('getStore', {
          params
        })
        .then(res => {
          this.list = res
        })
        .catch(err => {
          this.$message.error(err.msg || '查询商家排行榜失败')
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang='less' scoped>
.sidePanel {
  border-radius: 15px;
  &.animation {
    animation: tab-change 0.5s;
  }
  .header-container {
    position: relative;
    padding: 0 10px;
    padding-bottom: 0;
    .shape {
      position: absolute;
      z-index: 9;
      bottom: 0;
      width: calc(50% + 20px);
      height: calc(100% + 15px);
    }
    .header {
      widows: 100%;
      display: flex;
      border-radius: 20px 20px 0 0;
      overflow: hidden;
      margin-top: 10px;
      transition: all 0.3s;
      background: rgba(245, 247, 251, 1);

      .item {
        position: relative;
        width: 50%;
        padding: 10px 15px;
        text-align: center;
        font-size: 16px;
        background: rgba(245, 247, 251, 1);
        cursor: pointer;
        user-select: none;
        transition: all 0.3s;
        font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
        color: #808191;
        &:hover {
          filter: brightness(95%);
        }
        span {
          position: relative;
          z-index: 10;
          pointer-events: none;
        }
        &.active {
          color: rgba(71, 72, 73, 1);
          font-weight: bold;
          font-size: 17px;
        }
      }
    }
  }
  .content {
    position: relative;
    // padding-bottom: 5px;
    background: #fff;
    box-shadow: 0px 4px 12px -4px rgba(179, 181, 186, 0.08);
    border-radius: 0 15px 15px 15px;
    .filter {
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      align-items: center;
      padding: 0 15px;

      .switch {
        margin-top: 15px;
        width: fit-content;
        height: fit-content;
        background: rgba(245, 247, 251, 1);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        white-space: nowrap;

        &:active {
          background: rgba(0, 0, 0, 0.1);
        }
        .switch-item {
          height: fit-content;

          display: inline-block;
          padding: 8px 20px;
          font-size: 14px;
          color: rgba(128, 129, 145, 1);
          font-family: HarmonyOS Sans SC;
          // font-weight: bold;
          cursor: pointer;
          user-select: none;
          transition: all 0.3s;
          height: fit-content;
          text-align: center;

          span {
            position: relative;
            z-index: 10;
          }
          &.active {
            color: #fff;
          }
        }
        .switch--active {
          position: absolute;
          width: 50%;
          height: 100%;
          z-index: 9;
          border-radius: 5px;
          top: 0;
          left: 0;
          transition: all 0.4s;
          background: rgb(231, 20, 26);
        }
      }
      .filter-item {
        position: relative;
        padding: 4px 0;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        margin-top: 15px;
        &:active {
          filter: brightness(95%);
        }
        &.filter-date {
          padding: 9px 15px;
          width: fit-content;
          font-size: 14px;
          color: rgba(128, 129, 145, 1);
          .date-picker {
            position: absolute;
            z-index: 9;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
          /deep/.el-input__inner {
            width: fit-content;
            border: none;
            background: none;
            user-select: none;
            .el-range-input {
              background: none;
            }
          }
        }
      }
    }

    .list {
      position: relative;
      min-height: 520px;
      padding: 10px 0;
      overflow: auto;
      .item {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        position: relative;
        overflow: hidden;
        opacity: 0;
        animation: slide-top-in 0.2s forwards;
        .rank {
          width: 30px;
          height: 30px;
          position: relative;
          z-index: 1;
          span {
            position: relative;
            z-index: 10;
            display: inline-block;
            width: 100%;
            text-align: center;
            line-height: 30px;
            color: #fff;
            font-weight: bold;
            font-family: HarmonyOS Sans SC;
            font-size: 16px;
          }
          img {
            position: absolute;
            z-index: 9;
            width: 100%;
            height: 100%;
          }
        }
        .name {
          flex: 1;
          overflow: hidden;
          padding: 0 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: rgba(71, 72, 73, 1);
          font-family: HarmonyOS Sans SC;
          font-size: 16px;
          z-index: 1;
        }
        .value {
          color: rgba(27, 29, 33, 1);
          font-family: HarmonyOS Sans SC;
          font-weight: bold;
          font-size: 17px;
          z-index: 1;
        }
      }
    }
  }
}
@keyframes tab-change {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes slide-top-in {
  0% {
    // transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    // transform: translateY(0);
    opacity: 1;
  }
}
</style>